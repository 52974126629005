import { navigate } from 'gatsby'

export default function useNavigate(to) {
  if (typeof window !== 'undefined') {
    if (to.includes('http')) {
      window.location = to
      return
    }
    navigate(to)
  }
}
